<template>
  <div class="visibility-projet-setting">
    <div class="header">
      <div class="style-title-setting-global">
        {{
          $i18n.locale === 'fr'
            ? 'Gestion des visibilités des projets'
            : 'Project visibility Management'
        }}
      </div>
      <v-btn dark color="#5C2DD3" @click.stop="addNouveauFilter">
        <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
        {{ $i18n.locale === 'fr' ? 'Ajouter un filtre' : 'Add filter' }}
      </v-btn>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div v-else class="mt-3">
      <v-divider></v-divider>
      <v-row class="content-regie mt-2">
        <v-col class="groupe" col xs="12" sm="6" md="5">
          <div class="title color-crm bold-700 font-text">
            {{ $i18n.locale === 'fr' ? 'Filtres' : 'Filters' }}
          </div>
          <div
            class="list-users "
            v-if="getFiltersProject && getFiltersProject.length"
          >
            <v-list dense class="list-ensemble">
              <v-list-item-group color="#5C2DD3" class="list-ensemble-item">
                <v-list-item
                  button
                  v-for="filter in getFiltersProject"
                  :key="filter.id"
                  @click="handleClickfilter(filter)"
                  class="custom-list-group custom-list-group-vue"
                >
                  <v-list-item-icon
                    v-if="selectedFilter && selectedFilter.id == filter.id"
                  >
                    <font-awesome-icon class="color-crm" icon="check" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="capitalize">
                        {{ filter.name }}</span
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                  <v-menu
                    offset-y
                    close-on-click
                    min-width="197px !important"
                    max-width="197px !important"
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        text
                        small
                        v-bind="attrs"
                        v-on="on"
                        fab
                        class=" font-sz-20 mr-2 color-crm"
                      >
                        <font-awesome-icon
                          class="img-h-14 "
                          :class="{
                            'color-crm':
                              selectedFilter && selectedFilter.id == filter.id
                          }"
                          icon="ellipsis-h"
                        />
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        @click.prevent.stop="handleUpdateClick(filter)"
                      >
                        <v-list-item-title>
                          <v-btn
                            class="icon-action mr-3"
                            small
                            outlined
                            color="#5C2DD3"
                          >
                            <font-awesome-icon
                              class="img-h-14"
                              icon="pencil-alt"
                            />
                          </v-btn>
                          {{ $t('update') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click.prevent.stop="handleDeleteClick(filter)"
                      >
                        <v-list-item-title>
                          <v-btn
                            class="icon-action mr-3"
                            small
                            outlined
                            color="#5C2DD3"
                          >
                            <font-awesome-icon
                              class="img-h-14"
                              icon="trash-alt"
                            />
                          </v-btn>
                          {{ $t('deleted') }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div class="color-crm font-text font-sz-14 text-center mt-3" v-else>
            {{ $i18n.locale === 'fr' ? 'Aucun Filtre' : 'No Filters' }}
          </div>
        </v-col>
        <v-col class="utilisateur border-left-solid" col xs="12" sm="6" md="7">
          <div class="title block-flex-align-space-between">
            <div class="color-crm bold-700 font-text">
              {{ $t('utilisateurs') }}
            </div>
            <div class="mr-2">
              <v-progress-circular
                v-if="gettVisibilityProjectLoading"
                indeterminate
                color="#5C2DD3"
                class="sz-loader-icon"
              ></v-progress-circular>
            </div>
          </div>
          <div class="bloc-select-multi">
            <v-autocomplete
              :disabled="selectedFilter ? false : true"
              :clearable="true"
              color="#5C2DD3"
              multiple
              :placeholder="
                $i18n.locale === 'fr'
                  ? 'Rechercher un utilisateur'
                  : 'Search user'
              "
              item-color="#5C2DD3"
              chips
              return-object
              :deletable-chips="true"
              :small-chips="true"
              v-model="selectedUsers"
              :loading="getProjectListUsersLoading"
              :items="getUsers || []"
              :persistent-placeholder="true"
              :label="$t('utilisateurs')"
              class="mb-0"
              item-text="full_name"
              dense
              item-value="id"
              :auto-focus="false"
              :no-data-text="
                $i18n.locale === 'fr'
                  ? 'Aucune utilisateurs trouvée'
                  : 'No users found'
              "
              outlined
              :menu-props="{
                bottom: true,
                offsetY: true
              }"
            >
            </v-autocomplete>
            <div
              class="postion-btn"
              v-if="selectedUsers && selectedUsers.length"
            >
              <v-btn
                color="#5C2DD3"
                dark
                small
                @click="handelClickUsersToFilter(selectedUsers)"
              >
                {{ $t('btnValider') }}</v-btn
              >
            </div>
          </div>
          <div
            class="color-crm font-sz-12 font-text text-center"
            v-if="gettVisibilityProjectLoading && selectedFilter"
          >
            {{ $t('loading') }}
          </div>
          <div
            v-if="getProjectUsersLoading || getProjectChangeUsersLoading"
            class="color-crm font-sz-12 font-text text-center"
          >
            {{ $t('loading') }}
          </div>
          <div
            class="addListUsers"
            v-if="!gettVisibilityProjectLoading && selectedFilter"
          >
            <div
              class="list-users mt-2"
              :class="{
                'refact-height':
                  selectedFilter &&
                  selectedFilter.users &&
                  selectedFilter.users.length
              }"
              v-if="
                selectedFilter &&
                  selectedFilter.users &&
                  selectedFilter.users.length
              "
            >
              <v-list class="list-item-user" dense>
                <v-list-item
                  button
                  v-for="(user, index) in selectedFilter.users"
                  :key="user.id"
                  class="custom-list-group custom-list-group-vue"
                  :class="{
                    'border-top-solid': index == 0
                  }"
                >
                  <v-list-item-content>
                    <div class="block-flex-align-space-between">
                      <v-list-item-title
                        ><span class="font-sz-14 font-text bold-600">
                          {{ user.full_name }}</span
                        >
                        <v-list-item-subtitle>
                          <div class="user">
                            <strong class="color-crm font-sz-11 font-text">
                              {{ user.email }}</strong
                            >
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-title>

                      <v-list-item-action
                        v-if="computedFilter && computedFilter.length"
                      >
                        <v-menu offset-y close-on-click>
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn
                              small
                              v-bind="attrs"
                              v-on="on"
                              class="btn-filter small-btn"
                              dark
                              color="#3A167F"
                              :title="
                                $i18n.locale === 'fr'
                                  ? 'Changer filtre'
                                  : 'Change filter'
                              "
                            >
                              <v-icon class="sz-icon-plus"
                                >mdi-swap-horizontal</v-icon
                              >
                            </v-btn>
                          </template>

                          <v-list class="list-users-filter-change">
                            <v-list-item>
                              <v-list-item-title
                                class="color-crm font-sz-16 font-text bold-700 text-center"
                              >
                                {{
                                  $i18n.locale === 'fr'
                                    ? 'Liste des filtres'
                                    : 'List of filters'
                                }}
                              </v-list-item-title>
                            </v-list-item>
                            <v-divider class="mb-1"></v-divider>
                            <v-list-item
                              v-for="filter in computedFilter"
                              :key="'filter' + filter.id"
                              class="capitalize"
                            >
                              <v-list-item-title
                                @click.prevent.stop="
                                  handleupdateFilterUsersClick(filter, user)
                                "
                              >
                                {{ filter.name }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                      <v-list-item-action class="mr-3">
                        <v-btn
                          class="color-crm font-sz-14 bold-700 btn-deleted-user"
                          @click.prevent.stop="handleDeleteClick(user)"
                          :title="$t('deleted')"
                          fab
                          small
                          icon
                        >
                          X
                        </v-btn>
                      </v-list-item-action>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
            <div
              class="color-crm font-text font-sz-14 text-center mt-3"
              v-if="
                !getProjectUsersLoading &&
                  !getProjectDeleteUsersLoading &&
                  selectedFilter.users.length == 0
              "
            >
              {{ $i18n.locale === 'fr' ? 'Aucun utilisateur' : 'No user' }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- Ajouter Filter -->
    <v-dialog v-model="addFiltreModal" max-width="1000" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Ajouter un filtre' : 'Add filter' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addFiltreModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addFiltreModal">
            <FilterProjet
              :fields="getFieldsToFiltre"
              v-model="conditions"
            ></FilterProjet>
            <v-text-field
              class="mt-3"
              :label="$t('name')"
              dense
              :persistent-placeholder="true"
              outlined
              color="#5C2DD3"
              v-model="name"
              item-color="#5C2DD3"
              required
              validate-on-blur
              :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
            >
            </v-text-field>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="gettVisibilityProjectLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
            <div v-if="getVisibilityProjectError" class="error-msg">
              <ul v-if="Array.isArray(getVisibilityProjectError)">
                <li
                  v-for="(e, index) in getVisibilityProjectError"
                  :key="index"
                >
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getVisibilityProjectError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="ajouterFilter"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addFiltreModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete Filter and utilisteur -->
    <v-dialog v-model="ModalDeleteFilter" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              filterToDelete && filterToDelete.conditions
                ? $i18n.locale === 'fr'
                  ? 'Supprimer un filtre'
                  : 'Delete filter'
                : $i18n.locale === 'fr'
                ? 'Supprimer un utilisateur'
                : 'Delete user'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalDeleteFilter')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            <span v-if="filterToDelete && filterToDelete.conditions">
              {{
                $t('msgdeleted', {
                  msg: $i18n.locale === 'fr' ? 'le filtre' : 'the filter'
                })
              }}
              <strong class="capitalize">
                {{ filterToDelete ? filterToDelete.name : '' }}</strong
              >
              ?
            </span>
            <span v-else>
              {{
                $t('msgdeleted', {
                  msg: $i18n.locale === 'fr' ? "l'utilisateur" : 'the user'
                })
              }}
              <strong>
                {{ filterToDelete ? filterToDelete.full_name : '' }}</strong
              >
              ?
            </span>
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getProjectDeleteUsersLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getVisibilityProjectError" class="error-msg">
              <ul v-if="Array.isArray(getVisibilityProjectError)">
                <li
                  v-for="(e, index) in getVisibilityProjectError"
                  :key="index"
                >
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getVisibilityProjectError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleDeleteClickFilter"
            :loading="getProjectDeleteUsersLoading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalDeleteFilter')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modifier Filter -->
    <v-dialog
      v-model="updateFiltreModal"
      max-width="1000"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Modifier un filtre' : 'Edit filter' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('updateFiltreModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="updateFiltreModal" v-if="filterToUpdate">
            <FilterProjet
              :fields="getFieldsToFiltre"
              v-model="filterToUpdate.conditions"
            ></FilterProjet>
            <v-text-field
              class="mt-3"
              :label="$t('name')"
              dense
              :persistent-placeholder="true"
              outlined
              color="#5C2DD3"
              v-model="filterToUpdate.name"
              item-color="#5C2DD3"
              required
              validate-on-blur
              :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
            >
            </v-text-field>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="gettVisibilityProjectLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
            <div v-if="getVisibilityProjectError" class="error-msg">
              <ul v-if="Array.isArray(getVisibilityProjectError)">
                <li
                  v-for="(e, index) in getVisibilityProjectError"
                  :key="index"
                >
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getVisibilityProjectError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleUpdateClickFilter"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('updateFiltreModal')" small>
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import FilterProjet from '@/components/CrmIla26Component/FilterProjet'
export default {
  components: {
    FilterProjet
  },
  data() {
    return {
      loading: false,
      addFiltreModal: false,
      updateFiltreModal: false,
      ModalDeleteFilter: false,
      initLoading: true,
      selectedUsers: null,
      selectedFilter: null,
      filterToDelete: null,
      filterToUpdate: null,
      error: null,
      name: null,
      type: null,
      conditions: {
        and: [],
        or: []
      }
    }
  },

  methods: {
    ...mapActions([
      'fetchFieldsToFilter',
      'fetchListeUsers',
      'fetchListFiltres',
      'addFilter',
      'deleteFilter',
      'updateFiltre',
      'addFilterUsers',
      'deleteFilterUsers',
      'resetErrorFilter',
      'addUsersToFilters',
      'fetchUsersInFilter',
      'fetchOperatorsToFields',
      'fetchvalueOfFields'
    ]),
    resetModal() {
      this.filterToDelete = null
      this.filterToUpdate = null
      this.error = null
      this.name = null
      this.type = null
      this.conditions = {
        and: [],
        or: []
      }
      this.resetErrorFilter()
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'addFiltreModal') {
        this.$refs.addFiltreModal.resetValidation()
      }
      if (ref == 'updateFiltreModal') {
        this.$refs.updateFiltreModal.resetValidation()
      }
      this.resetModal()
    },
    addNouveauFilter() {
      this.conditions = {
        and: [],
        or: []
      }
      this.addFiltreModal = true
    },
    handleDeleteClick(deleted) {
      this.ModalDeleteFilter = true
      this.filterToDelete = deleted
    },
    handleUpdateClick(filter) {
      this.filterToUpdate = { ...filter }
      let conditionAnd = []
      let conditionOr = []
      if (
        this.filterToUpdate &&
        this.filterToUpdate.conditions &&
        this.filterToUpdate.conditions.and &&
        this.filterToUpdate.conditions.and.length
      ) {
        conditionAnd = this.filterToUpdate.conditions.and.map(and => {
          const field = this.getFieldsToFiltre.find(e => e.key == and.field_key)
          const operator = this.getOperatorToFiltre[
            and.field_key
          ].operators.find(e => e.key == and.operator)
          return {
            field_key: field,
            object:
              and.object && and.object.value ? and.object.value : and.object,
            operator: operator,
            value: and.value
          }
        })
      }
      if (
        this.filterToUpdate &&
        this.filterToUpdate.conditions &&
        this.filterToUpdate.conditions.or &&
        this.filterToUpdate.conditions.or.length
      ) {
        conditionOr = this.filterToUpdate.conditions.or.map(or => {
          const field = this.getFieldsToFiltre.find(e => e.key == or.field_key)
          const operator = this.getOperatorToFiltre[
            or.field_key
          ].operators.find(e => e.key == or.operator)
          return {
            field_key: field,
            object: or.object && or.object.value ? or.object.value : or.object,
            operator: operator,
            value: or.value
          }
        })
      }
      this.filterToUpdate.conditions = { and: conditionAnd, or: conditionOr }
      this.updateFiltreModal = true
    },
    validateFilter(filter, condition) {
      const validate = {
        status: 'success',
        errors: []
      }
      for (let index = 0; index < condition.and.length; index++) {
        const element = condition.and[index]
        if (!element.field_key || !element.operator) {
          validate.status = 'error'
          validate.errors.push(
            this.$i18n.locale === 'fr'
              ? 'Tous les champs sont obligatoires pour la condition ET'
              : 'All fields are required for the AND condition'
          )
        }
        if (
          element.operator &&
          element.operator.key &&
          element.operator.key != 'IS NULL' &&
          element.operator.key != 'IS NOT NULL' &&
          !element.value
        ) {
          validate.status = 'error'
          let msg = ''
          if (element.field_key && element.operator) {
            msg =
              this.$i18n.locale === 'fr'
                ? (msg =
                    'la valeur de champ de ' +
                    element.field_key.key +
                    ' est obligatoire pour la condition ET')
                : (msg =
                    'the field value of ' +
                    element.field_key.key +
                    ' is required for the ET condition')
            validate.errors.push(msg)
          }
        }
      }
      for (let index = 0; index < condition.or.length; index++) {
        const element = condition.or[index]
        if (!element.field_key || !element.operator) {
          validate.status = 'error'
          validate.errors.push(
            this.$i18n.locale === 'fr'
              ? 'Tous les champs sont obligatoires pour la condition OU'
              : 'All fields are required for the OR condition'
          )
        }
        if (
          element.operator &&
          element.operator.key &&
          element.operator.key != 'IS NULL' &&
          element.operator.key != 'IS NOT NULL' &&
          !element.value
        ) {
          validate.status = 'error'
          let msg = ''
          if (element.field_key && element.operator) {
            msg =
              this.$i18n.locale === 'fr'
                ? (msg =
                    'la valeur de champ de ' +
                    element.field_key.key +
                    ' est obligatoire pour la condition OU')
                : (msg =
                    'the field value of ' +
                    element.field_key.key +
                    ' is required for the OR condition')
            validate.errors.push(msg)
          }
        }
      }
      if (!filter.name) {
        validate.status = 'error'
        validate.errors.push(
          this.$i18n.locale === 'fr'
            ? 'Le nom est obligatoire'
            : 'Name is required'
        )
      }
      if (!filter.conditions.conditions.length) {
        validate.status = 'error'
        validate.errors.push(
          this.$i18n.locale === 'fr'
            ? 'Ajouter au minimum une condition'
            : 'Add a minimum of one condition'
        )
      }
      return validate
    },
    async ajouterFilter() {
      this.resetErrorFilter()
      this.error = null
      if (this.$refs.addFiltreModal.validate()) {
        const conditions = []
        if (
          this.conditions &&
          this.conditions.or &&
          this.conditions.or.length
        ) {
          conditions.push({
            glue: 'or',
            conditions: this.conditions.or.map(or => {
              let value = or.value ? or.value.id : null
              if (
                or.field_key &&
                or.field_key.key &&
                or.field_key.key == 'creation_date'
              ) {
                value = or.value ? or.value : null
              }
              return {
                field_key: or.field_key.key,
                object: or.object.value,
                operator: or.operator.key,
                value: value
              }
            })
          })
        }
        if (
          this.conditions &&
          this.conditions.and &&
          this.conditions.and.length
        ) {
          conditions.push({
            glue: 'and',
            conditions: this.conditions.and.map(and => {
              let value = and.value ? and.value.id : null
              if (
                and.field_key &&
                and.field_key.key &&
                and.field_key.key == 'creation_date'
              ) {
                value = and.value ? and.value : null
              }
              return {
                field_key: and.field_key.key,
                object: and.object.value,
                operator: and.operator.key,
                value: value
              }
            })
          })
        }
        const payload = {
          name: this.name,
          type: 'project',
          is_for_visibility: 1,
          conditions: {
            glue: 'and',
            conditions: conditions
          }
        }
        const validate = this.validateFilter(payload, this.conditions)
        if (validate.status === 'error') {
          this.error = validate.errors
          return
        }
        this.loading = true
        const response = await this.addFilter(payload)
        this.error = null
        if (response) {
          this.closeDialog('addFiltreModal')
        }
        this.loading = false
      }
    },
    async handleDeleteClickFilter() {
      if (this.filterToDelete && this.filterToDelete.conditions) {
        const response = await this.deleteFilter(this.filterToDelete)
        if (response) {
          this.closeDialog('ModalDeleteFilter')
          this.selectedFilter = null
          this.fetchListeUsers({
            exclude: [
              'team',
              'technicianPipe',
              'visibleTeams',
              'commercialItePipe',
              'userPipe',
              'depot'
            ]
          })
        }
      } else {
        const response = await this.deleteFilterUsers({
          filter: this.selectedFilter,
          users: this.filterToDelete,
          exclude: [
            'visibilityFilter',
            'technicianPipe',
            'visibleTeams',
            'team'
          ]
        })
        if (response) {
          this.closeDialog('ModalDeleteFilter')
          this.fetchListeUsers({
            exclude: [
              'team',
              'technicianPipe',
              'visibleTeams',
              'commercialItePipe',
              'userPipe',
              'depot'
            ]
          })
        }
      }
    },
    async handleUpdateClickFilter() {
      this.resetErrorFilter()
      this.error = null
      if (this.$refs.updateFiltreModal.validate()) {
        const conditions = []
        if (
          this.filterToUpdate &&
          this.filterToUpdate.conditions &&
          this.filterToUpdate.conditions.or &&
          this.filterToUpdate.conditions.or.length
        ) {
          conditions.push({
            glue: 'or',
            conditions: this.filterToUpdate.conditions.or.map(or => {
              let value = or.value && or.value.id ? or.value.id : null
              if (
                or.field_key &&
                or.field_key.key &&
                or.field_key.key == 'creation_date'
              ) {
                value = or.value ? or.value : null
              }
              return {
                field_key:
                  or.field_key && or.field_key.key
                    ? or.field_key.key
                    : or.field_key,
                object:
                  or.object.value && or.object.value
                    ? or.object.value
                    : or.object,
                operator:
                  or.operator && or.operator.key
                    ? or.operator.key
                    : or.operator,
                value: value
              }
            })
          })
        }
        if (
          this.filterToUpdate &&
          this.filterToUpdate.conditions &&
          this.filterToUpdate.conditions.and &&
          this.filterToUpdate.conditions.and.length
        ) {
          conditions.push({
            glue: 'and',
            conditions: this.filterToUpdate.conditions.and.map(and => {
              let value = and.value && and.value.id ? and.value.id : null
              if (
                and.field_key &&
                and.field_key.key &&
                and.field_key.key == 'creation_date'
              ) {
                value = and.value ? and.value : null
              }
              return {
                field_key:
                  and.field_key && and.field_key.key
                    ? and.field_key.key
                    : and.field_key,
                object:
                  and.object && and.object.value
                    ? and.object.value
                    : and.object,
                operator:
                  and.operator && and.operator.key
                    ? and.operator.key
                    : and.operator,
                value: value
              }
            })
          })
        }
        const payload = {
          id: this.filterToUpdate.id,
          name: this.filterToUpdate.name,
          type: 'project',
          conditions: {
            glue: 'and',
            conditions: conditions
          }
        }
        const validate = this.validateFilter(
          payload,
          this.filterToUpdate.conditions
        )
        if (validate.status === 'error') {
          this.error = validate.errors
          return
        }
        this.loading = true
        const response = await this.updateFiltre(payload)
        this.error = null
        if (response) {
          this.closeDialog('updateFiltreModal')
        }
        this.loading = false
      }
    },
    handleClickfilter(filter) {
      this.selectedFilter = filter
      this.fetchUsersInFilter({
        filter,
        exclude: [
          'visibilityFilter.conditions',
          'visibilityFilter.users',
          'technicianPipe',
          'visibleTeams',
          'team',
          'userPipe',
          'depot',
          'technicianPipe'
        ]
      })
    },
    handleupdateFilterUsersClick(addUserfilter, users) {
      this.addFilterUsers({
        addUserfilter,
        users,
        filter: this.selectedFilter,
        exclude: [
          'team',
          'userPipe',
          'visibleTeams',
          'technicianPipe',
          'commercialItePipe',
          'depot'
        ]
      })
    },
    async handelClickUsersToFilter(users) {
      const response = await this.addUsersToFilters({
        filter: this.selectedFilter,
        users: users,
        exclude: [
          'conditions',
          'users.userPipe',
          'users.visibleTeams',
          'users.team',
          'users.technicianPipe',
          'users.depot'
        ]
      })
      if (response) {
        this.selectedUsers = []
        this.fetchListeUsers({
          exclude: [
            'team',
            'technicianPipe',
            'visibleTeams',
            'commercialItePipe',
            'userPipe',
            'depot'
          ]
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'getFieldsToFiltre',
      'getUsers',
      'getFiltersProject',
      'getVisibilityProjectError',
      'gettVisibilityProjectLoading',
      'getProjectUsersLoading',
      'getProjectDeleteUsersLoading',
      'getProjectChangeUsersLoading',
      'getProjectListUsersLoading',
      'getOperatorToFiltre',
      'getValueToFields'
    ]),
    computedFilter: function() {
      return this.getFiltersProject.filter(
        filter => filter.id != this.selectedFilter.id
      )
    }
  },
  async mounted() {
    await this.fetchFieldsToFilter({
      for_visibility: 1
    })
    if (this.getFieldsToFiltre) {
      for (let index = 0; index < this.getFieldsToFiltre.length; index++) {
        const element = this.getFieldsToFiltre[index]
        if (!this.getOperatorToFiltre[element.key]) {
          this.fetchOperatorsToFields({
            field_key: element.key
          })
        }
      }
    }
    this.initLoading = true
    await this.fetchListFiltres({
      exclude: 'conditions',
      for_visibility: 1
    })
    this.initLoading = false
    this.fetchListeUsers({
      exclude: [
        'team',
        'technicianPipe',
        'visibleTeams',
        'commercialItePipe',
        'userPipe',
        'depot'
      ]
    })
  }
}
</script>
<style lang="scss" scoped>
.visibility-projet-setting {
  .groupe {
    .margin {
      margin: 12px;
    }
    .title {
      padding: 10px 0px;
    }
    .list-ensemble {
      padding: 0px 0px;

      .list-ensemble-item {
        .custom-list-group {
          border-top: 0px solid #d8d8d8;
          border-bottom: 1px solid #d8d8d8;

          border-left: 1px solid #d8d8d8;
          border-right: 1px solid #d8d8d8;
        }
      }
    }
    .list-users {
      max-height: calc(100vh - 281px) !important;
      height: calc(100vh - 281px) !important;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: auto;
      cursor: pointer;
      border-top: 1px solid #d8d8d8;
    }
    .list-users::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    .list-users::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .list-users::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(189, 189, 189, 1);
    }
  }
  .utilisateur {
    .title {
      padding: 10px 0px;
    }
    .bloc-select-multi {
      .postion-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: -19px;
        .button-add-users {
          font-size: 11px;
        }
      }
    }
    .addListUsers {
      .chargement {
        text-align: center;
        color: #2dabe2;
      }
      .list-item-user {
        padding: 0px 0 !important;

        // .v-list-item__content {
        // //   padding: 10px 0px !important;
        // }
        .custom-list-group {
          border-top: 0px solid #d8d8d8;
          border-bottom: 1px solid #d8d8d8;
          border-left: 1px solid #d8d8d8;
          border-right: 1px solid #d8d8d8;
        }
      }
      .list-users {
        max-height: calc(100vh - 360px) !important;
        height: calc(100vh - 360px) !important;
        margin-bottom: 0;
        overflow-y: auto;
        overflow-x: auto;
        &.refact-height {
          max-height: calc(100vh - 363px) !important;
          height: calc(100vh - 363px) !important;
          margin-bottom: 0;
          overflow-y: auto;
          overflow-x: auto;
        }
      }
      .list-users::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      .list-users::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #f0eef8;
        border-radius: 4px;
        background-color: rgba(238, 238, 238, 1) !important;
      }
      .list-users::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(189, 189, 189, 1);
      }
    }
  }
}
.list-users-filter-change {
  max-height: calc(100vh - 360px) !important;
  height: calc(100vh - 360px) !important;
  margin-bottom: 0;
  overflow-y: auto;
  overflow-x: auto;
  .v-list-item {
    min-height: 35px;
  }
}
.list-users-filter-change::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.list-users-filter-change::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 4px;
  background-color: rgba(238, 238, 238, 1) !important;
}
.list-users-filter-change::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(189, 189, 189, 1);
}
</style>
