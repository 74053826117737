var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filter-project pb-3"},[_c('div',{staticClass:"mb-2 mt-2"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Afficher les projets correspondant à TOUTES ces conditions :' : 'Show projects that meet ALL these conditions:')+" ")]),(_vm.localValue.and)?_c('div',{staticClass:"mt-3"},_vm._l((_vm.localValue.and),function(item,index){return _c('div',{key:index,staticClass:"list-element mb-2"},[_c('v-autocomplete',{staticClass:"mr-2",attrs:{"clearable":true,"color":"#5C2DD3","placeholder":_vm.$t('search'),"item-color":"#5C2DD3","items":_vm.selectObject,"persistent-placeholder":true,"label":_vm.$t('object'),"item-text":"text","dense":"","item-value":"value","no-data-text":_vm.$t('noDataOption'),"outlined":"","menu-props":{
            bottom: true,
            offsetY: true
          }},model:{value:(item.object),callback:function ($$v) {_vm.$set(item, "object", $$v)},expression:"item.object"}}),_c('v-autocomplete',{staticClass:"mr-2",attrs:{"clearable":true,"color":"#5C2DD3","placeholder":_vm.$t('search'),"item-color":"#5C2DD3","items":_vm.getFieldsToFiltre,"return-object":"","persistent-placeholder":true,"label":_vm.$t('champ'),"item-text":"name","dense":"","item-value":"key","no-data-text":_vm.$t('noDataOption'),"outlined":"","menu-props":{
            bottom: true,
            offsetY: true
          }},on:{"input":function($event){return _vm.changeKeyField(item.field_key, index, 'and')}},model:{value:(item.field_key),callback:function ($$v) {_vm.$set(item, "field_key", $$v)},expression:"item.field_key"}}),_c('v-autocomplete',{staticClass:"mr-2",attrs:{"clearable":true,"color":"#5C2DD3","placeholder":_vm.$t('search'),"item-color":"#5C2DD3","items":_vm.selectedOperatorsAnd[index] || [],"loading":_vm.gettVisibilityProjectOperators,"return-object":"","persistent-placeholder":true,"label":_vm.$t('operator'),"item-text":"label","dense":"","item-value":"key","no-data-text":_vm.$t('noDataOption'),"outlined":"","menu-props":{
            bottom: true,
            offsetY: true
          }},on:{"input":function($event){return _vm.changeOperator(item.operator)}},model:{value:(item.operator),callback:function ($$v) {_vm.$set(item, "operator", $$v)},expression:"item.operator"}}),(_vm.shouldShowDatePicker(item))?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('date'),"outlined":"","readonly":"","clearable":"","dense":"","persistent-placeholder":true,"placeholder":_vm.$t('msgSelectDate'),"prepend-inner-icon":"mdi-calendar","color":"#5C2DD3"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-icon',{staticClass:"mr-3",attrs:{"title":_vm.$i18n.locale === 'fr'
                      ? 'Ajouter une condition'
                      : 'Add condition'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addCondition('and')}}},[_vm._v("mdi-plus")]),_c('v-icon',{staticClass:"color-crm-bold font-sz-16 mr-3 ml-2",attrs:{"title":"Type condition"}},[_vm._v(_vm._s(_vm.$i18n.locale === 'fr' ? 'ET' : 'AND'))]),_c('v-icon',{staticClass:"mr-3 ml-3",attrs:{"title":_vm.$i18n.locale === 'fr'
                      ? 'Supprimer une condition'
                      : 'Delete condition'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removecondition(index, 'and')}}},[_vm._v("mdi-trash-can-outline")])]},proxy:true}],null,true),model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"disabled":item.isHidden,"no-title":"","locale":"fr","color":"#5C2DD3","header-color":"#5C2DD3","event-color":"704ad1"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1):_c('v-autocomplete',{staticClass:"mr-2",attrs:{"disabled":item.isHidden,"clearable":true,"color":"#5C2DD3","placeholder":_vm.$t('search'),"item-color":"#5C2DD3","items":_vm.selectedValueAnd[index] || [],"loading":_vm.gettVisibilityProjectOperators,"return-object":"","persistent-placeholder":true,"label":_vm.$t('value'),"item-text":"name","dense":"","item-value":"id","no-data-text":_vm.$t('noDataOption'),"outlined":"","menu-props":{
            bottom: true,
            offsetY: true
          }},on:{"change":_vm.changeValue},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-icon',{staticClass:"mr-3",attrs:{"title":_vm.$i18n.locale === 'fr'
                  ? 'Ajouter une condition'
                  : 'Add condition'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addCondition('and')}}},[_vm._v("mdi-plus")]),_c('v-icon',{staticClass:"color-crm-bold font-sz-16 mr-3 ml-2",attrs:{"title":"Type condition"}},[_vm._v(_vm._s(_vm.$i18n.locale === 'fr' ? 'ET' : 'AND'))]),_c('v-icon',{staticClass:"mr-3 ml-3",attrs:{"title":_vm.$i18n.locale === 'fr'
                  ? 'Supprimer une condition'
                  : 'Delete condition'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removecondition(index, 'and')}}},[_vm._v("mdi-trash-can-outline")])]},proxy:true}],null,true),model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)}),0):_vm._e(),(!_vm.localValue.and || !_vm.localValue.and.length)?_c('div',{staticClass:"add-to-list-action font-text font-sz-13 flex-center",on:{"click":function($event){return _vm.addCondition('and')}}},[_c('font-awesome-icon',{staticClass:"img-h-14 mr-2",attrs:{"icon":"plus"}}),_c('p',{staticClass:"font-sz-14 mb-0"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Ajouter une condition' : 'Add condition')+" ")])],1):_vm._e()]),_c('div',{staticClass:"mb-2 mt-2"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Et correspond à une de ces conditions :' : 'And corresponds to one of these conditions:')+" ")]),(_vm.localValue.or)?_c('div',{staticClass:"mt-3"},_vm._l((_vm.localValue.or),function(item,index){return _c('div',{key:index,staticClass:"list-element mb-2"},[_c('v-autocomplete',{staticClass:"mr-2",attrs:{"clearable":true,"color":"#5C2DD3","placeholder":_vm.$t('search'),"item-color":"#5C2DD3","items":_vm.selectObject,"persistent-placeholder":true,"label":_vm.$t('object'),"item-text":"text","dense":"","item-value":"value","no-data-text":_vm.$t('noDataOption'),"outlined":"","menu-props":{
            bottom: true,
            offsetY: true
          }},model:{value:(item.object),callback:function ($$v) {_vm.$set(item, "object", $$v)},expression:"item.object"}}),_c('v-autocomplete',{staticClass:"mr-2",attrs:{"clearable":true,"color":"#5C2DD3","placeholder":_vm.$t('search'),"item-color":"#5C2DD3","items":_vm.getFieldsToFiltre,"return-object":"","persistent-placeholder":true,"label":_vm.$t('champ'),"item-text":"name","dense":"","item-value":"key","no-data-text":_vm.$t('noDataOption'),"outlined":"","menu-props":{
            bottom: true,
            offsetY: true
          }},on:{"input":function($event){return _vm.changeKeyField(item.field_key, index, 'or')}},model:{value:(item.field_key),callback:function ($$v) {_vm.$set(item, "field_key", $$v)},expression:"item.field_key"}}),_c('v-autocomplete',{staticClass:"mr-2",attrs:{"clearable":true,"color":"#5C2DD3","placeholder":_vm.$t('search'),"item-color":"#5C2DD3","items":_vm.selectedOperatorsOr[index] || [],"loading":_vm.gettVisibilityProjectOperators,"return-object":"","persistent-placeholder":true,"label":_vm.$t('operator'),"item-text":"label","dense":"","item-value":"key","no-data-text":_vm.$t('noDataOption'),"outlined":"","menu-props":{
            bottom: true,
            offsetY: true
          }},on:{"input":function($event){return _vm.changeOperator(item.operator)}},model:{value:(item.operator),callback:function ($$v) {_vm.$set(item, "operator", $$v)},expression:"item.operator"}}),(
            item && item.field_key && item.field_key.key == 'creation_date'
          )?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('date'),"outlined":"","readonly":"","clearable":"","dense":"","persistent-placeholder":true,"placeholder":_vm.$t('msgSelectDate'),"prepend-inner-icon":"mdi-calendar","color":"#5C2DD3"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-icon',{staticClass:"mr-3",attrs:{"title":_vm.$i18n.locale === 'fr'
                      ? 'Ajouter une condition'
                      : 'Add condition'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addCondition('or')}}},[_vm._v("mdi-plus")]),_c('v-icon',{staticClass:"color-crm-bold font-sz-16 mr-3 ml-2",attrs:{"title":"Type condition"}},[_vm._v(_vm._s(_vm.$i18n.locale === 'fr' ? 'OU' : 'OR'))]),_c('v-icon',{staticClass:"mr-3 ml-3",attrs:{"title":_vm.$i18n.locale === 'fr'
                      ? 'Supprimer une condition'
                      : 'Delete condition'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removecondition(index, 'or')}}},[_vm._v("mdi-trash-can-outline")])]},proxy:true}],null,true),model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"disabled":item.isHidden,"no-title":"","locale":"fr","color":"#5C2DD3","header-color":"#5C2DD3","event-color":"704ad1"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1):_c('v-autocomplete',{staticClass:"mr-2",attrs:{"disabled":item.isHidden,"clearable":true,"color":"#5C2DD3","placeholder":_vm.$t('search'),"item-color":"#5C2DD3","items":_vm.selectedValueOr[index] || [],"loading":_vm.gettVisibilityProjectOperators,"persistent-placeholder":true,"return-object":"","label":_vm.$t('value'),"item-text":"name","dense":"","item-value":"id","no-data-text":_vm.$t('noDataOption'),"outlined":"","menu-props":{
            bottom: true,
            offsetY: true
          }},on:{"input":_vm.changeValue},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-icon',{staticClass:"mr-3",attrs:{"title":_vm.$i18n.locale === 'fr'
                  ? 'Ajouter une condition'
                  : 'Add condition'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addCondition('or')}}},[_vm._v("mdi-plus")]),_c('v-icon',{staticClass:"color-crm-bold font-sz-16 mr-3 ml-2",attrs:{"title":"Type condition"}},[_vm._v(_vm._s(_vm.$i18n.locale === 'fr' ? 'OU' : 'OR'))]),_c('v-icon',{staticClass:"mr-3 ml-3",attrs:{"title":_vm.$i18n.locale === 'fr'
                  ? 'Supprimer une condition'
                  : 'Delete condition'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removecondition(index, 'or')}}},[_vm._v("mdi-trash-can-outline")])]},proxy:true}],null,true),model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)}),0):_vm._e(),(!_vm.localValue.or || !_vm.localValue.or.length)?_c('div',{staticClass:"add-to-list-action font-text font-sz-13 flex-center",on:{"click":function($event){return _vm.addCondition('or')}}},[_c('font-awesome-icon',{staticClass:"img-h-14 mr-2",attrs:{"icon":"plus"}}),_c('p',{staticClass:"font-sz-14 mb-0"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Ajouter une condition' : 'Add condition')+" ")])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }