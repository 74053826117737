<template>
  <div class="filter-project pb-3">
    <div class="mb-2 mt-2">
      <strong>
        {{
          $i18n.locale === 'fr'
            ? 'Afficher les projets correspondant à TOUTES ces conditions :'
            : 'Show projects that meet ALL these conditions:'
        }}
      </strong>
      <div v-if="localValue.and" class="mt-3">
        <div
          class="list-element mb-2"
          v-for="(item, index) in localValue.and"
          :key="index"
        >
          <v-autocomplete
            class="mr-2"
            :clearable="true"
            color="#5C2DD3"
            :placeholder="$t('search')"
            item-color="#5C2DD3"
            v-model="item.object"
            :items="selectObject"
            :persistent-placeholder="true"
            :label="$t('object')"
            item-text="text"
            dense
            item-value="value"
            :no-data-text="$t('noDataOption')"
            outlined
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
          </v-autocomplete>
          <v-autocomplete
            class="mr-2"
            :clearable="true"
            color="#5C2DD3"
            :placeholder="$t('search')"
            item-color="#5C2DD3"
            v-model="item.field_key"
            :items="getFieldsToFiltre"
            @input="changeKeyField(item.field_key, index, 'and')"
            return-object
            :persistent-placeholder="true"
            :label="$t('champ')"
            item-text="name"
            dense
            item-value="key"
            :no-data-text="$t('noDataOption')"
            outlined
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
          </v-autocomplete>
          <v-autocomplete
            class="mr-2"
            :clearable="true"
            color="#5C2DD3"
            :placeholder="$t('search')"
            item-color="#5C2DD3"
            v-model="item.operator"
            :items="selectedOperatorsAnd[index] || []"
            @input="changeOperator(item.operator)"
            :loading="gettVisibilityProjectOperators"
            return-object
            :persistent-placeholder="true"
            :label="$t('operator')"
            item-text="label"
            dense
            item-value="key"
            :no-data-text="$t('noDataOption')"
            outlined
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
          </v-autocomplete>
          <v-menu
            :close-on-content-click="false"
            offset-y
            v-if="shouldShowDatePicker(item)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="item.value"
                :label="$t('date')"
                outlined
                readonly
                clearable
                dense
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                :placeholder="$t('msgSelectDate')"
                prepend-inner-icon="mdi-calendar"
                color="#5C2DD3"
              >
                <template v-slot:append-outer>
                  <v-icon
                    @click.prevent.stop="addCondition('and')"
                    :title="
                      $i18n.locale === 'fr'
                        ? 'Ajouter une condition'
                        : 'Add condition'
                    "
                    class="mr-3"
                    >mdi-plus</v-icon
                  >
                  <v-icon
                    title="Type condition"
                    class="color-crm-bold font-sz-16 mr-3 ml-2"
                    >{{ $i18n.locale === 'fr' ? 'ET' : 'AND' }}</v-icon
                  >
                  <v-icon
                    :title="
                      $i18n.locale === 'fr'
                        ? 'Supprimer une condition'
                        : 'Delete condition'
                    "
                    class="mr-3 ml-3"
                    @click.prevent.stop="removecondition(index, 'and')"
                    >mdi-trash-can-outline</v-icon
                  >
                </template></v-text-field
              >
            </template>

            <v-date-picker
              :disabled="item.isHidden"
              v-model="item.value"
              no-title
              locale="fr"
              color="#5C2DD3"
              header-color="#5C2DD3"
              event-color="704ad1"
            >
            </v-date-picker>
          </v-menu>
          <v-autocomplete
            class="mr-2"
            v-else
            :disabled="item.isHidden"
            :clearable="true"
            color="#5C2DD3"
            :placeholder="$t('search')"
            item-color="#5C2DD3"
            v-model="item.value"
            @change="changeValue"
            :items="selectedValueAnd[index] || []"
            :loading="gettVisibilityProjectOperators"
            return-object
            :persistent-placeholder="true"
            :label="$t('value')"
            item-text="name"
            dense
            item-value="id"
            :no-data-text="$t('noDataOption')"
            outlined
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
            <template v-slot:append-outer>
              <v-icon
                @click.prevent.stop="addCondition('and')"
                :title="
                  $i18n.locale === 'fr'
                    ? 'Ajouter une condition'
                    : 'Add condition'
                "
                class="mr-3"
                >mdi-plus</v-icon
              >
              <v-icon
                title="Type condition"
                class="color-crm-bold font-sz-16 mr-3 ml-2"
                >{{ $i18n.locale === 'fr' ? 'ET' : 'AND' }}</v-icon
              >
              <v-icon
                :title="
                  $i18n.locale === 'fr'
                    ? 'Supprimer une condition'
                    : 'Delete condition'
                "
                class="mr-3 ml-3"
                @click.prevent.stop="removecondition(index, 'and')"
                >mdi-trash-can-outline</v-icon
              >
            </template>
          </v-autocomplete>
        </div>
      </div>
      <div
        v-if="!localValue.and || !localValue.and.length"
        class="add-to-list-action font-text font-sz-13 flex-center"
        @click="addCondition('and')"
      >
        <font-awesome-icon class="img-h-14 mr-2" icon="plus" />
        <p class="font-sz-14 mb-0">
          {{
            $i18n.locale === 'fr' ? 'Ajouter une condition' : 'Add condition'
          }}
        </p>
      </div>
    </div>
    <div class="mb-2 mt-2">
      <strong>
        {{
          $i18n.locale === 'fr'
            ? 'Et correspond à une de ces conditions :'
            : 'And corresponds to one of these conditions:'
        }}
      </strong>
      <div v-if="localValue.or" class="mt-3">
        <div
          class="list-element mb-2"
          v-for="(item, index) in localValue.or"
          :key="index"
        >
          <v-autocomplete
            class="mr-2"
            :clearable="true"
            color="#5C2DD3"
            :placeholder="$t('search')"
            item-color="#5C2DD3"
            v-model="item.object"
            :items="selectObject"
            :persistent-placeholder="true"
            :label="$t('object')"
            item-text="text"
            dense
            item-value="value"
            :no-data-text="$t('noDataOption')"
            outlined
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
          </v-autocomplete>
          <v-autocomplete
            class="mr-2"
            :clearable="true"
            color="#5C2DD3"
            :placeholder="$t('search')"
            item-color="#5C2DD3"
            v-model="item.field_key"
            :items="getFieldsToFiltre"
            @input="changeKeyField(item.field_key, index, 'or')"
            return-object
            :persistent-placeholder="true"
            :label="$t('champ')"
            item-text="name"
            dense
            item-value="key"
            :no-data-text="$t('noDataOption')"
            outlined
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
          </v-autocomplete>
          <v-autocomplete
            class="mr-2"
            :clearable="true"
            color="#5C2DD3"
            :placeholder="$t('search')"
            item-color="#5C2DD3"
            v-model="item.operator"
            :items="selectedOperatorsOr[index] || []"
            @input="changeOperator(item.operator)"
            :loading="gettVisibilityProjectOperators"
            return-object
            :persistent-placeholder="true"
            :label="$t('operator')"
            item-text="label"
            dense
            item-value="key"
            :no-data-text="$t('noDataOption')"
            outlined
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
          </v-autocomplete>
          <v-menu
            :close-on-content-click="false"
            offset-y
            v-if="
              item && item.field_key && item.field_key.key == 'creation_date'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="item.value"
                :label="$t('date')"
                outlined
                readonly
                clearable
                dense
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                :placeholder="$t('msgSelectDate')"
                prepend-inner-icon="mdi-calendar"
                color="#5C2DD3"
              >
                <template v-slot:append-outer>
                  <v-icon
                    @click.prevent.stop="addCondition('or')"
                    :title="
                      $i18n.locale === 'fr'
                        ? 'Ajouter une condition'
                        : 'Add condition'
                    "
                    class="mr-3"
                    >mdi-plus</v-icon
                  >
                  <v-icon
                    title="Type condition"
                    class="color-crm-bold font-sz-16 mr-3 ml-2"
                    >{{ $i18n.locale === 'fr' ? 'OU' : 'OR' }}</v-icon
                  >
                  <v-icon
                    :title="
                      $i18n.locale === 'fr'
                        ? 'Supprimer une condition'
                        : 'Delete condition'
                    "
                    class="mr-3 ml-3"
                    @click.prevent.stop="removecondition(index, 'or')"
                    >mdi-trash-can-outline</v-icon
                  >
                </template>
              </v-text-field>
            </template>

            <v-date-picker
              :disabled="item.isHidden"
              v-model="item.value"
              no-title
              locale="fr"
              color="#5C2DD3"
              header-color="#5C2DD3"
              event-color="704ad1"
            >
            </v-date-picker>
          </v-menu>
          <v-autocomplete
            class="mr-2"
            v-else
            :disabled="item.isHidden"
            :clearable="true"
            color="#5C2DD3"
            :placeholder="$t('search')"
            item-color="#5C2DD3"
            v-model="item.value"
            @input="changeValue"
            :items="selectedValueOr[index] || []"
            :loading="gettVisibilityProjectOperators"
            :persistent-placeholder="true"
            return-object
            :label="$t('value')"
            item-text="name"
            dense
            item-value="id"
            :no-data-text="$t('noDataOption')"
            outlined
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
            <template v-slot:append-outer>
              <v-icon
                @click.prevent.stop="addCondition('or')"
                :title="
                  $i18n.locale === 'fr'
                    ? 'Ajouter une condition'
                    : 'Add condition'
                "
                class="mr-3"
                >mdi-plus</v-icon
              >
              <v-icon
                title="Type condition"
                class="color-crm-bold font-sz-16 mr-3 ml-2"
                >{{ $i18n.locale === 'fr' ? 'OU' : 'OR' }}</v-icon
              >
              <v-icon
                :title="
                  $i18n.locale === 'fr'
                    ? 'Supprimer une condition'
                    : 'Delete condition'
                "
                class="mr-3 ml-3"
                @click.prevent.stop="removecondition(index, 'or')"
                >mdi-trash-can-outline</v-icon
              >
            </template>
          </v-autocomplete>
        </div>
      </div>
      <div
        v-if="!localValue.or || !localValue.or.length"
        class="add-to-list-action font-text font-sz-13 flex-center"
        @click="addCondition('or')"
      >
        <font-awesome-icon class="img-h-14 mr-2" icon="plus" />
        <p class="font-sz-14 mb-0">
          {{
            $i18n.locale === 'fr' ? 'Ajouter une condition' : 'Add condition'
          }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
// import Multiselect from 'vue-multiselect'
// import DatePicker from 'vue2-datepicker'
// import 'vue2-datepicker/index.css'
export default {
  components: {
    // Multiselect,
    // DatePicker
  },
  props: {
    fields: { required: true },
    value: {
      default: () => {
        return {
          conditions: {
            and: [],
            or: []
          }
        }
      }
    }
  },
  data() {
    return {
      isDate: false,
      localValue: { ...this.value },
      selectedOperatorsAnd: [],
      selectedOperatorsLinkAnd: [],
      selectedValueAnd: [],
      selectedOperatorsOr: [],
      selectedOperatorsLinkOr: [],
      selectedValueOr: [],
      selectObject: [{ value: 'project', text: 'Project' }],
      changeFieldkey: {
        index: null,
        condition: null
      }
    }
  },

  methods: {
    ...mapActions([
      'fetchOperatorsToFields',
      'fetchvalueOfFields',
      'fetchFieldsToFilter'
    ]),
    shouldShowDatePicker(item) {
      return item && item.field_key && item.field_key.key === 'creation_date'
    },
    changeKeyField(keyField, index, condition) {
      if (keyField && keyField.key) {
        if (!this.getOperatorToFiltre[keyField.key]) {
          this.fetchOperatorsToFields({
            field_key: keyField.key
          })
        }
      }
      this.changeFieldkey = {
        index: index,
        condition: condition
      }
      if (
        this.localValue &&
        this.localValue.and &&
        this.localValue.and.length
      ) {
        this.selectedOperatorsAnd = []
        this.selectedOperatorsLinkAnd = []
        this.selectedValueAnd = []
        this.appelValue()
      }
      if (this.localValue && this.localValue.or && this.localValue.or.length) {
        this.selectedOperatorsOr = []
        this.selectedOperatorsLinkOr = []
        this.selectedValueOr = []
        this.appelValue()
      }
    },
    changeOperator() {
      this.localValue.and.map(and => {
        and.isHidden = false
        if (
          and.operator &&
          and.operator.key &&
          (and.operator.key == 'IS NULL' || and.operator.key == 'IS NOT NULL')
        ) {
          and.isHidden = true
          and.value = null
        }
      })
      this.localValue.or.map(or => {
        or.isHidden = false
        if (
          or.operator &&
          or.operator.key &&
          (or.operator.key == 'IS NULL' || or.operator.key == 'IS NOT NULL')
        ) {
          or.isHidden = true
          or.value = null
        }
      })
    },
    addCondition(etatFiltre) {
      if (etatFiltre == 'and') {
        this.localValue.and.push({
          object: { value: 'project', text: 'Project' },
          field_key: '',
          operator: '',
          value: '',
          isHidden: false
        })
        for (let index = 0; index < this.localValue.and.length; index++) {
          const keyField = this.localValue.and[index]
          if (!keyField.field_key) {
            this.selectedOperatorsAnd[index] = []
            this.selectedOperatorsLinkAnd[index] = []
            this.selectedValueAnd[index] = []
          }
        }
      }
      if (etatFiltre == 'or') {
        this.localValue.or.push({
          object: { value: 'project', text: 'Project' },
          field_key: '',
          operator: '',
          value: '',
          isHidden: false
        })
        for (let index = 0; index < this.localValue.or.length; index++) {
          const keyField = this.localValue.or[index]
          if (!keyField.field_key) {
            this.selectedOperatorsOr[index] = []
            this.selectedOperatorsLinkOr[index] = []
            this.selectedValueOr[index] = []
          }
        }
      }
    },
    removecondition(index, etatFiltre) {
      if (etatFiltre == 'and') {
        this.localValue.and.splice(index, 1)
      } else {
        this.localValue.or.splice(index, 1)
      }
      this.changeValue()
    },
    changeValue() {
      this.$emit('input', this.localValue)
      this.$emit('change', this.localValue)
    },
    async appelValue() {
      if (this.localValue && this.localValue.and) {
        for (let index = 0; index < this.localValue.and.length; index++) {
          const keyField = this.localValue.and[index]
          if (
            this.changeFieldkey.index == index &&
            this.changeFieldkey.condition === 'and' &&
            (keyField.value || keyField.operator)
          ) {
            keyField.operator = ''
            keyField.value = ''
          }
          keyField.isHidden = false
          if (
            keyField.operator.key == 'IS NULL' ||
            keyField.operator.key == 'IS NOT NULL'
          ) {
            keyField.isHidden = true
            keyField.value = null
          }
          if (keyField.field_key) {
            this.selectedOperatorsAnd[index] = this.getOperatorToFiltre[
              keyField.field_key.key
            ].operators

            this.selectedOperatorsLinkAnd[index] = this.getOperatorToFiltre[
              keyField.field_key.key
            ].links
            if (
              this.selectedOperatorsLinkAnd[index] &&
              this.selectedOperatorsLinkAnd[index].length
            ) {
              for (
                let indexLink = 0;
                indexLink < this.selectedOperatorsLinkAnd[index].length;
                indexLink++
              ) {
                const link =
                  this.selectedOperatorsLinkAnd[index][indexLink] &&
                  this.selectedOperatorsLinkAnd[index][indexLink].value
                    ? this.selectedOperatorsLinkAnd[index][indexLink].value
                    : null
                // if (
                //   link == '/api/coms-sed' ||
                //   link == '/api/csubstages-ids-names-status'
                // ) {
                if (link) {
                  await this.fetchvalueOfFields(link)
                }
                // }
              }
            } else {
              await this.fetchvalueOfFields(
                this.selectedOperatorsLinkAnd[index]
              )
            }
            if (this.getValueToFields) {
              if (keyField.field_key.key == 'department') {
                this.selectedValueAnd[index] = this.getValueToFields.map(
                  value => {
                    return {
                      name: value.department,
                      id: value.id
                    }
                  }
                )
              } else if (keyField.field_key.key == 'comm_sed') {
                this.selectedValueAnd[index] = this.getValueToFields.map(
                  value => {
                    return {
                      name: value.full_name,
                      id: value.id
                    }
                  }
                )
              } else {
                this.selectedValueAnd[index] = this.getValueToFields
              }
            }
          }
        }
      }
      if (this.localValue && this.localValue.or) {
        for (let index = 0; index < this.localValue.or.length; index++) {
          const keyField = this.localValue.or[index]
          if (
            this.changeFieldkey.index == index &&
            this.changeFieldkey.condition === 'or' &&
            (keyField.value || keyField.operator)
          ) {
            keyField.operator = ''
            keyField.value = ''
          }
          keyField.isHidden = false
          if (
            keyField.operator.key == 'IS NULL' ||
            keyField.operator.key == 'IS NOT NULL'
          ) {
            keyField.isHidden = true
            keyField.value = null
          }
          if (keyField.field_key) {
            this.selectedOperatorsOr[index] = this.getOperatorToFiltre[
              keyField.field_key.key
            ].operators
            this.selectedOperatorsLinkOr[index] = this.getOperatorToFiltre[
              keyField.field_key.key
            ].links
            if (
              this.selectedOperatorsLinkOr[index] &&
              this.selectedOperatorsLinkOr[index].length
            ) {
              for (
                let indexLink = 0;
                indexLink < this.selectedOperatorsLinkOr[index].length;
                indexLink++
              ) {
                const link = this.selectedOperatorsLinkOr[index][indexLink]
                  .value
                // if (
                //   link == '/api/coms-sed' ||
                //   link == '/api/csubstages-ids-names-status'
                // ) {
                await this.fetchvalueOfFields(link)
                // }
              }
            } else {
              await this.fetchvalueOfFields(this.selectedOperatorsLinkOr[index])
            }
            if (this.getValueToFields) {
              if (keyField.field_key.key == 'department') {
                this.selectedValueOr[index] = this.getValueToFields.map(
                  value => {
                    return {
                      name: value.department,
                      id: value.id
                    }
                  }
                )
              } else if (keyField.field_key.key == 'comm_sed') {
                this.selectedValueOr[index] = this.getValueToFields.map(
                  value => {
                    return {
                      name: value.full_name,
                      id: value.id
                    }
                  }
                )
              } else {
                this.selectedValueOr[index] = this.getValueToFields
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getFieldsToFiltre',
      'getOperatorToFiltre',
      'getValueToFields',
      'gettVisibilityProjectOperators'
    ])
  },
  watch: {
    value(event) {
      this.localValue = { ...event }
    }
  },
  mounted() {
    this.appelValue().then(() => {
      if (this.localValue && this.localValue.and) {
        for (let index = 0; index < this.localValue.and.length; index++) {
          const keyField = this.localValue.and[index]
          if (
            keyField &&
            keyField.field_key &&
            keyField.field_key.key &&
            keyField.field_key.key != 'creation_date'
          ) {
            const value = this.selectedValueAnd[index].find(
              e => e.id == keyField.value
            )
            keyField.value = value
          }
        }
      }
      if (this.localValue && this.localValue.or) {
        for (let index = 0; index < this.localValue.or.length; index++) {
          const keyField = this.localValue.or[index]
          if (
            keyField &&
            keyField.field_key &&
            keyField.field_key.key &&
            keyField.field_key.key != 'creation_date'
          ) {
            const value = this.selectedValueOr[index].find(
              e => e.id == keyField.value
            )
            keyField.value = value
          }
        }
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.filter-project {
  .add-to-list-action {
    color: #5c2dd3;
    cursor: pointer;
    width: 22%;
    &.disabled {
      color: #5c2dd3;
      cursor: default;
    }
  }
  .list-element {
    display: flex;
    align-items: center;
    .value-type {
      flex-shrink: 3;
    }
    .bolc-and,
    .bloc-or {
      display: flex;
    }
    .action {
      cursor: pointer;
    }
  }
}
// @media screen and (max-width: 990px) {
//   .filter-project {
//     .add-to-list-action {
//       width: 40%;
//     }
//     .list-element {
//       display: initial;
//       .value-type {
//         margin-bottom: 5px;
//         max-width: initial;
//       }
//       .bolc-and,
//       .bloc-or {
//         display: flex;
//         justify-content: flex-end;
//       }
//       .action {
//         margin-left: 0px;
//       }
//     }
//   }
// }
</style>
<style lang="scss">
.mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
  .mx-input {
    display: inline-block;
    width: 100%;
    height: 42px;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    color: #35495e;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
  }
}
.filter-project {
  .list-element {
    .multiselect--disabled {
      background: #ededed;
      pointer-events: none;
      border-radius: 5px;
      opacity: 2.6;
    }
    .value-type {
      .multiselect__select {
        height: 40px;
      }
    }
  }
}
</style>
